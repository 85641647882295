/* Floating Feedback Button */
.feedbackButton {
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 10px 15px;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s ease, transform 0.3s ease;
    animation: breathe 4s ease-in-out infinite;
}
  
.feedbackButton:hover {
    background-color: #0056b3;
    transform: scale(1.05);
    animation: none;  /* Stop breathing animation on hover */
}

@keyframes breathe {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
  
/* Modal Backdrop */
.modalBackdrop {
    background-color: rgba(0, 0, 0, 0.5);
}
  