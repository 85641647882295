/* Add any custom styles that Bootstrap classes don't cover */
.progress-bar {
  transition: width 0.6s ease;
}

@keyframes celebrate {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.progress-bar[aria-valuenow="100"] {
  animation: celebrate 0.5s ease;
}

.progressContainer {
  /* background: #ee7160; */
  /* background: #f8f9fa; */
  padding: 1.5rem;
  /* border-radius: 12px 12px 0 0; */
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.05); */
  /* box-shadow: 0 5px 5px rgba(0,0,0,0.05); */
  margin: 0 auto;
  border-bottom: 1px solid #eaecef;
  /* box-shadow: 0 5px 5px rgba(0,0,0,0.05); */
}

.statsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.progressBar {
  height: 10px;
  background-color: #e9ecef;
  border-radius: 8px;
  overflow: hidden;
}

.progressFill {
  height: 100%;
  background: linear-gradient(90deg, #0d6efd, #0a58ca);
  transition: width 0.6s ease;
}

/* When completed */
.progressFillComplete {
  background: linear-gradient(90deg, #198754, #146c43);
}

.message {
  text-align: center;
}

.messageDesktop {
  display: none;
}

.messageMobile {
  margin-top: 0.5rem;
  text-align: center;
}

/* Skill Progress Section */
.skillBars {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.skillBar {
  flex: 1;
  min-width: 0;
}

.skillProgress {
  height: 6px;
  background-color: #e9ecef;
  border-radius: 4px;
  overflow: hidden;
}

.skillProgressFill {
  height: 100%;
  transition: width 0.6s ease;
}

@media (min-width: 768px) {
  .messageDesktop {
    display: block;
  }
  
  .messageMobile {
    display: none;
  }

  .skillBars {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 767px) {
  .progressContainer {
    padding: 1rem;
  }
  
  .statsRow {
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .skillBars {
    gap: 0.75rem;
    flex-direction: column;
  }
}

@keyframes celebrate {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.progressFill[aria-valuenow="100"] {
  animation: celebrate 0.5s ease;
}

.badgeRow {
  display: flex;
  align-items: center;
  margin-left: 12px; /* Offset to compensate for the negative margin of first badge */
}

.badgeRow > * {
  margin-left: -12px; /* Creates overlap effect */
}

.badgeMore {
  font-size: 0.8rem;
  color: #666;
  cursor: pointer;
  margin-left: 0; /* Reset margin for the +X indicator */
  padding-left: 4px; /* Add a small gap after the last badge */
}