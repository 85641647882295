.formSignin {
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.formSignin .formFloating:focus-within {
  z-index: 2;
}

.passwordInput {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.confirmPasswordInput {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
