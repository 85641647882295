/* #CybotCookiebotDialogBodyButtonDecline {
  background-color: white !important;
  color: #8d8d8d !important;
  border-color: #8d8d8d !important;
}

#CybotCookiebotDialogHeader {
  opacity: 0.2 !important;
}

#CybotCookiebotDialogPoweredByText {
  opacity: 0.2 !important;
} */

div#root {
  background-color: rgb(248 249 250);
}

.btn-primary {
  background-color: #2f56bc;
  border-color: #2f56bc;
}

.btn-primary:hover {
  background-color: #2848a3;
  border-color: #2848a3;
}

.btn-primary:disabled {
  background-color: #89aed8 !important;
  border-color: #89aed8 !important;
}

.btn-outline-primary {
  color: #2f56bc;
  border-color: #2f56bc;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2f56bc;
  border-color: #2f56bc;
}

.btn-outline-primary:focus,
.btn-outline-primary:active {
  color: #fff;
  background-color: #223c89 !important;
  border-color: #223c89 !important;
}

.btn-outline-secondary {
  color: #5a5a5a !important;
  background-color: #fff !important;
}

.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #5a5a5a !important;
}

.btn-outline-primary.active {
  color: #fff !important;
  background-color: #2f56bc !important;
}

.btn-outline-primary.active:hover {
  color: #fff !important;
  background-color: #2848a3 !important;
}

.navbar {
  background-color: #2f56bc !important;
}

div.testimonial {
  /* background-color: #fff9e4 !important; */
  background-color: #fff !important;
  border: 1px solid #e2e8f0 !important;
}

.feature-thumbnail {
  /* background-color: #fff9e4 !important; */
  background-color: #fff !important;
  border: 1px solid #e2e8f0 !important;
}

/* .accordion-item {
  background-color: fff !important;
}

.accordion-button {
  background-color: #fff !important;
} */

.text-bg-primary {
  background-color: #2f56bc !important;
}

.border-primary {
  border-color: #2f56bc !important;
}

/* .alert-warning {
  color: #1e293b !important;
  background-color: #f1f5f9 !important;
  border-color: #e2e8f0 !important;
} */

mark {
  background-color: #dbeafe !important;
}

.form-check-input:checked {
  background-color: #2f63e3 !important;
  border-color: #2f63e3 !important;
}

/* Success Button Styles */
.btn-success {
  background-color: #348b4c !important;  /* Custom desaturated green */
  border-color: #348b4c !important;
}

.btn-success:hover {
  background-color: #2a7541 !important;  /* Darker */
  border-color: #2a7541 !important;
}

.btn-success:focus,
.btn-success:active {
  background-color: #236535 !important;  /* Darkest */
  border-color: #236535 !important;
}

/* Root Variables for Consistency */
:root {
  --primary: #2f56bc;          /* darker desaturated blue */
  --primary-dark: #2848a3;     /* darker desaturated darker blue */
  --primary-darker: #223c89;   /* darker desaturated darkest blue */
  --primary-light: #466fc4;    /* darker desaturated light blue */
  --primary-lighter: #89aed8;  /* darker desaturated lightest blue */
  --gray-light: #e5e9ee;       /* slightly darker gray */
  --gray-border: #d4d9e1;      /* slightly darker border */
  --highlight: #cfd9e8;        /* darker desaturated highlight */
}
