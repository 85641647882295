/* Header.module.css */
.navLink {
  font-size: 1rem;
  color: #f8f9fa !important; /* Bootstrap default text color */
  transition: transform 0.2s ease !important;
  display: inline-block; /* Required for transform to work */
}

.btn {
  font-size: 1rem;
}

.dropdown-item {
  font-size: 1rem;
}

.mobileOnly {
  display: none; /* Hidden by default on larger screens */
}

@media (max-width: 991px) {
  .navLink {
    font-size: 1rem;
  }
  
  .btn {
    font-size: 1rem;
  }

  .dropdown-item {
    font-size: 1rem;
    padding: 8px 16px;
  }

  .mobileOnly {
    display: block; /* Show mobile items on small screens */
  }
  
  .profileItem {
    padding-left: 20px; /* Indent profile items to show hierarchy */
  }
}

/* .navLink:hover {
  color: #0d6efd !important; /* Bootstrap primary blue color */
/* } */

/* Override Bootstrap's default hover behavior if necessary */
/* .navLink:hover,
.navLink:focus {
  color: #0d6efd !important;
} */

/* If the link is inside a list item, we might need to target that as well */
/* li .navLink:hover,
li .navLink:focus {
  color: #0d6efd !important;
} */

.navbarCollapse {
  transition: max-height 0.3s ease-out, opacity 0.2s ease-out;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.navbarCollapse.show {
  max-height: 1000px; /* Adjust this value based on your menu's maximum height */
  opacity: 1;
}

@media (min-width: 992px) {
  .navbarCollapse {
    transition: none;
    max-height: none;
    opacity: 1;
    overflow: visible;
  }
}

.navLink:hover {
  transform: translateY(-2px);
}
