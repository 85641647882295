/* HomePage.module.css */

.listItemLink {
  text-decoration: none; /* Remove underlining by default */
  color: inherit; /* Inherit the color from parent */
  transition: color 0.3s ease; /* Smooth transition */
}

.listItemLink:hover {
  /* text-decoration: underline; Add underlining on hover */
  color: #ee7160; /* Change to Bootstrap blue on hover */
}
