.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h3 {
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  color: #666;
  transition: color 0.2s;
}

.closeButton:hover {
  color: #333;
}

.content {
  padding: 1.5rem;
  overflow-y: auto;
}

.category {
  margin-bottom: 2rem;
}

.category h4 {
  margin-bottom: 1rem;
  color: #666;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
}

.badgeGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.badgeItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  background: #f8f9fa;
  transition: transform 0.2s;
}

.badgeItem:hover {
  transform: translateY(-2px);
}

.badgeInfo {
  flex: 1;
}

.badgeName {
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.earnedDate {
  font-size: 0.8rem;
  color: #666;
} 