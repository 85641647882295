.formSignin {
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.formSignin .formFloating:focus-within {
  z-index: 2;
}

.formSignin input[type="email"] {  
  margin-bottom: 10px;
}