.badge {
  display: inline-flex;
  align-items: center;
}

.badgeIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s;
}

.badgeIcon:hover {
  transform: scale(1.1);
}

/* Badge color variants */
.badgeDefault {
  background: #f8f9fa;
  color: #6c757d;
  border: 1px solid #6c757d;
  box-shadow: 0 0 5px #ffffff;
}

.badgeBronze {
  background: #f3e5d8;
  color: #cd7f32;
  border: 1px solid #cd7f32;
  box-shadow: 0px 0 5px #ffffff;
}

.badgeSilver {
  background: #f1f1f1;
  color: #808080;
  border: 1px solid #808080;
  box-shadow: 0 0 5px #ffffff;
}

.badgeGold {
  background: #fff3cd;
  color: #ffd700;
  border: 1px solid #ffd700;
  box-shadow: 0 0 5px #ffffff;
}

.badgePlatinum {
  background: #e8f4f8;
  color: #89cff0;
  border: 1px solid #89cff0;
  box-shadow: 0 0 5px #ffffff;
}