/* USPs.module.css */

.uspItem {
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .icon {
    font-size: 2rem;
    color: #212529;
    transition: color 0.3s ease;
  }
  
  .text {
    font-weight: 500;
    font-size: 0.9rem;
    color: #212529;
    transition: color 0.3s ease;
    margin-bottom: 0;
  }
  
  .uspItem:hover .icon,
  .uspItem:hover .text {
    /* color: #ee7160; */
    color: #3d69db;
  }
  