.thumbIcon {
  transition: all 0.2s ease-in-out;
}

.thumbIcon:hover {
  transform: scale(1.2);
}

.success, .danger {
  transition: color 0.2s ease-in-out;
}

.filled {
  transition: transform 0.2s ease-in-out;
}

.reasonContainer {
  animation: slideDown 0.3s ease-in-out;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* padding: 0 0.5rem; */
}

.reasonInput {
  flex: 1;
}

.reasonInput::placeholder {
  /* color: #adb5bd; */
  color: grey;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}