.roundedTable {
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: 0;
  min-width: 100%;
  width: auto;
}

.roundedTable th,
.roundedTable td {
  padding: 0.75rem;
  border-right: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  vertical-align: top;
  background-color: #fff;
}

.roundedTable th {
  border-top: 1px solid #dee2e6;
  font-weight: 500;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.roundedTable th:first-child,
.roundedTable td:first-child {
  border-left: 1px solid #dee2e6;
}

.roundedTable th:first-child {
  border-top-left-radius: 8px;
}

.roundedTable th:last-child {
  border-top-right-radius: 8px;
}

.roundedTable tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}

.roundedTable tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}