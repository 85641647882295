.select {
  text-align: center;
  text-align-last: center;
  background-color: #fcfcfc;
}

@media (min-width: 768px) {
  .select {
    text-align: left;
    text-align-last: left;
  }
  
  .select option {
    text-align: left;
  }
}