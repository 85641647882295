.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  animation: appear 0.3s ease-out forwards;
}

.badgeWrapper {
  position: relative;
  margin: 1rem 0;
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.badgeScale {
  transform: scale(4);
  position: relative;
  z-index: 2;
}

.particle {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  pointer-events: none;
  left: 50%;
  top: 50%;
  opacity: 0;
}

/* Particle animations for different directions */
.particle:nth-child(1) { animation: particleTopLeft 1.5s ease-out infinite; background: #ffd700; }
.particle:nth-child(2) { animation: particleTop 1.5s ease-out infinite 0.1s; background: #ff6b6b; }
.particle:nth-child(3) { animation: particleTopRight 1.5s ease-out infinite 0.2s; background: #4CAF50; }
.particle:nth-child(4) { animation: particleRight 1.5s ease-out infinite 0.3s; background: #2196F3; }
.particle:nth-child(5) { animation: particleBottomRight 1.5s ease-out infinite 0.4s; background: #9C27B0; }
.particle:nth-child(6) { animation: particleBottom 1.5s ease-out infinite 0.5s; background: #ffd700; }
.particle:nth-child(7) { animation: particleBottomLeft 1.5s ease-out infinite 0.6s; background: #ff6b6b; }
.particle:nth-child(8) { animation: particleLeft 1.5s ease-out infinite 0.7s; background: #4CAF50; }

@keyframes particleTopLeft {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  100% { transform: translate(-150px, -150px) scale(1.5); opacity: 0; }
}

@keyframes particleTop {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  100% { transform: translate(0, -150px) scale(1.5); opacity: 0; }
}

@keyframes particleTopRight {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  100% { transform: translate(150px, -150px) scale(1.5); opacity: 0; }
}

@keyframes particleRight {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  100% { transform: translate(150px, 0) scale(1.5); opacity: 0; }
}

@keyframes particleBottomRight {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  100% { transform: translate(150px, 150px) scale(1.5); opacity: 0; }
}

@keyframes particleBottom {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  100% { transform: translate(0, 150px) scale(1.5); opacity: 0; }
}

@keyframes particleBottomLeft {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  100% { transform: translate(-150px, 150px) scale(1.5); opacity: 0; }
}

@keyframes particleLeft {
  0% { transform: translate(-50%, -50%) scale(0); opacity: 1; }
  100% { transform: translate(-150px, 0) scale(1.5); opacity: 0; }
}

h4, h5 {
  margin: 0;
  text-align: center;
  color: #333;
}

@keyframes appear {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.badgeGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 2rem;
  margin-bottom: 1rem;
}

.badgeCounter {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #666;
  text-align: center;
} 