.formSignin {
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.formSignin .formFloating:focus-within {
  z-index: 2;
}

.formSignin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.formSignin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
